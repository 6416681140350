import '@mrhenry/wp--bugsnag-config';
import '@mrhenry/wp--autoplay-in-view';

import { MrAccordion, MrDisplayValue, MrInputSink, MrMatchesValue, MrPlayPauseToggle } from '@mrhenry/wp--mr-interactive';

import './modules/mr-artist-slideshow-indicator';
import './modules/mr-carousel';
import './modules/mr-video';
import './modules/mr-hotspots-list';

customElements.define( 'mr-accordion', MrAccordion );
customElements.define( 'mr-display-value', MrDisplayValue );
customElements.define( 'mr-input-sink', MrInputSink );
customElements.define( 'mr-matches-value', MrMatchesValue );
customElements.define( 'mr-play-pause-toggle', MrPlayPauseToggle );

class MrArtistSlideshowIndicator extends HTMLElement {
	#cursorHandler = ( e: MouseEvent ) => {
		this.style.setProperty( '--cursor-thumbnail--left', `${e.clientX}px` );
		this.style.setProperty( '--cursor-thumbnail--top', `${e.clientY}px` );
	};

	#cursorHandlerOnce = () => {
		this.style.setProperty( '--cursor-display-indicator', 'block' );
	};

	connectedCallback() {
		if ( !window.matchMedia( '(prefers-reduced-motion: reduce), not all and (hover)' ).matches ) {
			window.addEventListener( 'mousemove', this.#cursorHandler, {
				passive: true,
			} );

			window.addEventListener( 'mousemove', this.#cursorHandlerOnce, {
				passive: true,
				once: true,
			} );
		}
	}

	disconnectedCallback() {
		window.removeEventListener( 'mousemove', this.#cursorHandler );
		window.removeEventListener( 'mousemove', this.#cursorHandlerOnce );
	}
}

customElements.define( 'mr-artist-slideshow-indicator', MrArtistSlideshowIndicator );

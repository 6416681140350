
import { MrCarousel } from '@mrhenry/wp--mr-interactive';

class UbnCarousel extends MrCarousel {
	#observer: IntersectionObserver = new IntersectionObserver( async( entries ) => {
		for ( const entry of entries ) {
			if ( ( entry.target !== this ) ) {
				continue;
			}

			const video = this.querySelector<HTMLVideoElement>( '[data-slide="current"] video' );
			if ( !video ) {
				return;
			}

			if ( entry.isIntersecting && video.paused ) {
				try {
					await video.play();
				} catch { }
			} else if ( !entry.isIntersecting && !video.paused ) {
				video.pause();
			}
		}
	} );

	override connectedCallback(): void {
		super.connectedCallback();

		this.#observer.observe( this );

		requestAnimationFrame( async() => {
			const video = this.querySelector<HTMLVideoElement>( '[data-slide="current"] video' );
			if ( !video || !video.paused ) {
				return;
			}

			try {
				await video.play();
			} catch { }
		} );
	}

	override async willChangeSlide( oldIndex: number, newIndex: number ): Promise<void> {
		const video = this.querySelector<HTMLVideoElement>( '[data-slide="next"] video' );
		if ( video ) {
			try {
				await video.play();
			} catch {}
		}

		await super.willChangeSlide( oldIndex, newIndex );
	}

	override async didChangeSlide( oldIndex: number, newIndex: number ): Promise<void> {
		const allVideos = this.querySelectorAll<HTMLVideoElement>( 'video' );
		for ( const video of allVideos ) {
			if ( video.closest( '[data-slide="current"]' ) ) {
				continue;
			}

			video.pause();
		}

		await super.didChangeSlide( oldIndex, newIndex );
	}
}

customElements.define( 'mr-carousel', UbnCarousel );

class MrHotspotsList extends HTMLElement {
	#data: Array<Hotspot> | null = null;

	#loaded = false;

	#valueChangeHandler = () => {
		const selectedFilter = this.querySelector( 'input:checked' );

		if ( !selectedFilter ) return;

		const filterBy = selectedFilter.getAttribute( 'value' );

		const allItems = Array.from( this.querySelectorAll( '.hotspots-list__list-item' ) );

		for ( let i = 0; i < allItems.length; i++ ) {
			const item = allItems[i];

			item.removeAttribute( 'hidden' );

			if ( filterBy && filterBy !== 'everything' && item.getAttribute( 'data-type' ) !== filterBy ) {
				item.setAttribute( 'hidden', '' );
			}
		}
	};

	connectedCallback() {
		this.#load();

		this.addEventListener( 'change', this.#valueChangeHandler );
	}

	async #load() {
		if ( this.#loaded ) return;

		this.#loaded = true;

		try {
			const resp = await fetch( 'https://hotspots.usbynight.be/wp-json/hotspot/list.json' );

			if ( !resp.ok ) throw new Error( `Failed to fetch hotspots: ${resp.status} ${resp.statusText}` );

			const data = await resp.json() as Array<Hotspot>;

			this.#data = data;

			this.#render();
		} catch ( err ) {
			console.warn( err );
		}
	}

	#render() {
		if ( !this.#data?.length ) return;

		this.innerHTML = '';

		const filter = this.#renderHotspotsFilter( this.#data );
		if ( filter ) {
			this.appendChild( filter );
		}

		this.appendChild( this.#renderHotspotsList( this.#data ) );
	}

	#renderHotspotsFilter( hotspots: Array<Hotspot> ): HTMLElement|undefined {
		const fieldset = document.createElement( 'FIELDSET' );
		fieldset.className = 'hotspots-list__filter';

		{
			const legend = document.createElement( 'LEGEND' );
			legend.className = 'u-visually-hidden';
			legend.innerText = 'Filter by';

			fieldset.appendChild( legend );
		}

		{
			const uniqueOptions = new Set<string>( [
				'everything',
			] );

			for ( let i = 0; i < hotspots.length; i++ ) {
				if ( hotspots[i].type ) uniqueOptions.add( hotspots[i].type! );
			}

			if ( uniqueOptions.size === 0 ) return;

			const options = Array.from( uniqueOptions );

			const radioGroup = document.createElement( 'DIV' );
			radioGroup.className = 'hotspots-list__filter__radio-group';

			for ( let i = 0; i < options.length; i++ ) {
				const option = options[i];

				const radioWithLabel = document.createElement( 'DIV' );
				radioWithLabel.className = 'hotspots-list__filter__radio-with-label type-b';

				{
					const input = document.createElement( 'INPUT' ) as HTMLInputElement;
					input.setAttribute( 'type', 'radio' );
					input.setAttribute( 'id', `hotspots-filter--${option}` );
					input.setAttribute( 'name', 'hotspots-filter' );
					input.setAttribute( 'value', option );
					input.checked = option === 'everything';

					radioWithLabel.appendChild( input );
				}

				{
					const label = document.createElement( 'LABEL' );
					label.className = 'type-b';
					label.setAttribute( 'for', `hotspots-filter--${option}` );
					label.innerText = hotspotTypeLabel( option );

					radioWithLabel.appendChild( label );
				}

				radioGroup.appendChild( radioWithLabel );
			}

			fieldset.appendChild( radioGroup );
		}

		return fieldset;
	}

	#renderHotspotsList( hotspots: Array<Hotspot> ): HTMLElement {
		const list = document.createElement( 'UL' );
		list.className = 'hotspots-list__list';

		for ( let i = 0; i < hotspots.length; i++ ) {
			list.appendChild( this.#renderHotspot( hotspots[i] ) );
		}

		return list;
	}

	#renderHotspot( hotspot: Hotspot ): HTMLElement {
		const listItem = document.createElement( 'LI' );
		listItem.className = 'hotspots-list__list-item';

		if ( hotspot.type ) {
			listItem.setAttribute( 'data-type', hotspot.type );
		}

		{
			const title = document.createElement( 'DIV' );
			title.className = 'hotspots-list__list-item__title type-c';
			title.innerText = hotspot.title;

			listItem.appendChild( title );
		}

		{
			const hotspotType = document.createElement( 'DIV' );
			hotspotType.className = 'hotspots-list__list-item__type type-c';

			if ( hotspot.type ) {
				hotspotType.innerText = hotspotTypeLabel( hotspot.type );
			}
			listItem.appendChild( hotspotType );
		}

		{
			const text = document.createElement( 'DIV' );
			text.className = 'hotspots-list__list-item__text type-c';
			text.innerText = hotspot.content_plain_text ?? '';

			listItem.appendChild( text );
		}

		{
			const links = document.createElement( 'DIV' );
			links.className = 'hotspots-list__list-item__links';

			const placeID = hotspot.location?.place_id;
			const lat = hotspot.location?.lat;
			const lng = hotspot.location?.lng;
			if ( placeID && lat && lng ) {
				const placeURL = new URL( 'https://www.google.com/maps/search/' );
				placeURL.searchParams.set( 'api', '1' );
				placeURL.searchParams.set( 'query', `${lat},${lng}` );
				placeURL.searchParams.set( 'query_place_id', placeID );

				const link = document.createElement( 'A' );
				link.setAttribute( 'href', placeURL.href );
				link.setAttribute( 'target', '_blank' );

				const linkText = document.createElement( 'SPAN' );
				linkText.className = 'type-c';
				linkText.innerText = 'Map';

				link.appendChild( linkText );
				links.appendChild( link );
			}

			if ( Array.isArray( hotspot.links ) ) {
				for ( let j = 0; j < hotspot.links.length; j++ ) {
					const hotspotLink = hotspot.links[j].link;

					if ( !hotspotLink?.url ) continue;

					const link = document.createElement( 'A' );
					link.setAttribute( 'href', hotspotLink.url );
					link.setAttribute( 'target', '_blank' );

					const linkText = document.createElement( 'SPAN' );
					linkText.className = 'type-c';
					linkText.innerText = hotspotLink.title ?? hotspotLink.url;

					link.appendChild( linkText );
					links.appendChild( link );
				}
			}

			listItem.appendChild( links );
		}

		return listItem;
	}
}


window.customElements.define( 'mr-hotspots-list', MrHotspotsList );

function hotspotTypeLabel( hotspotType: string ): string {
	switch ( hotspotType ) {
		case 'food':
			return 'Food';
		case 'sight':
			return 'Sight';
		case 'drinks':
			return 'Drinks';
		case 'fun':
			return 'Fun';
		case 'hotel':
			return 'Hotel';
		case 'everything':
			return 'Everything';

		default:
			return hotspotType;
	}
}

interface Hotspot {
	id: number
	title: string
	content?: string
	content_plain_text?: string
	type?: string
	poster_image?: string
	links?: Array<{link?: Link}>
	location?: Location
}

export interface Link {
	title?: string
	url?: string
	target?: string
}

interface Location {
	address?: string
	lat?: number
	lng?: number
	zoom?: number
	place_id?: string
	name?: string
	street_number?: string
	street_name?: string
	city?: string
	state?: string
	state_short?: string
	post_code?: string
	country?: string
	country_short?: string
}
